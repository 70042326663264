<template>
  <div
    class="ml-error-page h100 d-flex flex-column justify-content-center align-items-center"
  >
    <h1 class="ml-error-page__smile">:(</h1>
    <div class="ml-error-page__bold">
      Не задан уникальный идентификатор
    </div>
    <div>
      Перезагрузите страницу сайта и начните заново
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    message() {
      return this.$route?.params?.message ?? ''
    },
    subMessage() {
      return this.$route?.params?.subMessage ?? ''
    }
  }
}
</script>

<style lang="scss" scoped>
.ml-error-page {
  text-align: center;
  &__smile {
    font-size: 91px;
    margin-bottom: 2rem;
  }
  &__bold {
    font-weight: 500;
    font-size: 1.25rem;
  }
}
</style>
